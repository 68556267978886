import axios from 'axios'

const API_PREFIX = "accesos-estadisticas";

const ENDPOINTS = {
    graficasAccesos: params => axios(`${API_PREFIX}/graficas-accesos`, { params }),
    informeAccesos: params => axios(`${API_PREFIX}/informe-accesos`, { params }),
    tablaAccesos: params => axios(`${API_PREFIX}/tabla-accesos`, { params }),
    comparativoAccesos: params => axios(`${API_PREFIX}/comparativo-accesos`, { params }),
    listaLeecheros: params => axios(`${API_PREFIX}/lista-leecheros`, { params }),
};

export default ENDPOINTS
